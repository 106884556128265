<template>
  <div class="card-container">
    <div class="flex-between">
      <div class="sub2">{{ card.name }}</div>
      <div class="flex-align">
        <div class="main"><span class="price body4-bold">{{ card.price.price|currencyNum }}</span>원
          <span v-if="card.price.is_subscription" class="sub4 size-14">/월</span></div>
        <i v-if="closeEnable" class="material-icons sub4 unselect" style="font-size:17px;margin-left:8px" @click="$emit('delete')">close</i>
      </div>
    </div>
    <div class="box-account weight-500" v-if="card.option">
      <div class="flex-between">
        <div class="sub2">무통장 입금 결제기능</div>
        <div v-if="!card.add_option" @click="addAccount" class="primary unselect" style="text-decoration: underline">추가</div>
        <div v-else class="flex-align">
          <div><span class="price body4-bold">{{ (card.option.price.price - card.price.price)|currencyNum }}</span>원
            <span v-if="card.option.price.is_subscription" class="sub4 size-14">/월</span></div>
          <i class="material-icons sub4 unselect" style="font-size:17px;margin-left:8px" @click="removeAccount">close</i>
        </div>
      </div>
      <div class="body5 sub3" style="margin-top:4px"><i class="material-icons-outlined size-18" style="vertical-align: text-bottom">info</i> 그 외 결제기능은 별도 신청이 필요합니다.</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardCart",
    props: {
      card: {
        type: Object
      },
      closeEnable: {
        type: Boolean,
        default: true
      }
    },
    created() {
    },
    data() {
      return {
      }
    },
    methods: {
      addAccount() {
        this.card.add_option = true;
      },
      removeAccount() {
        this.card.add_option = false;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .card-container
    border-top 1px solid $gray2
    padding 16px 0
  .box-account
    border-radius 8px
    background-color $gray3
    padding 8px 12px
    margin-top 12px
</style>
